import React, { useEffect, useState } from 'react';
import { Row } from '../../models/offerModel';
import { Tab, Tabs, Table } from "react-bootstrap";
import './calendar.css';

interface TableProps {
    timeline?: Row;
}

const Calendar: React.FC<TableProps> = ({ timeline }) => {

    const years = timeline?.timeline;
    const firstYear = years ? Object.keys(years)[0] : undefined;


    return (

        <Tabs defaultActiveKey={firstYear}>
            {years && Object.keys(years).map((year) => {
                const arrMonth = years[year].map((year) => { return year.month });
               
                return (

                    <Tab eventKey={year} title={year} tabClassName="nav-item pr-2 pt-2 pb-2 pl-2" key={year}>
                        <div className='pt-2'>
                            <table id="TABLE_1" className='pt-2'>
                                <thead id="THEAD_2">
                                    <tr id="TR_3">
                                        <th id="TH_4">Янв</th>
                                        <th id="TH_4">Фев</th>
                                        <th id="TH_4">Мар</th>
                                        <th id="TH_4">Апр</th>
                                        <th id="TH_4">Май</th>
                                        <th id="TH_4">Июн</th>
                                    </tr>
                                </thead>
                                <tbody id="TBODY_16">
                                    <tr id="TR_3">
                                        <td id="TD_18">
                                            {arrMonth.some((m) => m === "01") &&  <div id="DIV_4"></div>}
                                        </td>
                                        <td id="TD_18">{arrMonth.some((m) => m === "02") && <div id="DIV_4"></div>}</td>
                                        <td id="TD_18">{arrMonth.some((m) => m === "03") && <div id="DIV_4"></div>}</td>
                                        <td id="TD_18">{arrMonth.some((m) => m === "04") && <div id="DIV_4"></div>}</td>
                                        <td id="TD_18">{arrMonth.some((m) => m === "05") && <div id="DIV_4"></div>}</td>
                                        <td id="TD_18">{arrMonth.some((m) => m === "06") && <div id="DIV_4"></div>}</td>
                                    </tr>
                                </tbody>
                                <thead id="THEAD_2">
                                    <tr id="TR_3">
                                        <th id="TH_4">Июл</th>
                                        <th id="TH_4">Авг</th>
                                        <th id="TH_4">Сен</th>
                                        <th id="TH_4">Окт</th>
                                        <th id="TH_4">Ноя</th>
                                        <th id="TH_4">Дек</th>
                                    </tr>
                                </thead>
                                <tbody id="TBODY_16">
                                    <tr id="TR_17">
                                        <td id="TD_18"> {arrMonth.some((m) => m === "07") &&  <div id="DIV_4"></div>}</td>
                                        <td id="TD_18" >{arrMonth.some((m) => m === "08") &&  <div id="DIV_4"></div>}</td>
                                        <td id="TD_18" >{arrMonth.some((m) => m === "09") &&  <div id="DIV_4"></div>}</td>
                                        <td id="TD_18" >{arrMonth.some((m) => m === "10") &&  <div id="DIV_4"></div>}</td>
                                        <td id="TD_18" >{arrMonth.some((m) => m === "11") &&  <div id="DIV_4"></div>}</td>
                                        <td id="TD_18" >{arrMonth.some((m) => m === "12") &&  <div id="DIV_4"></div>}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Tab>


                )
            }

            )}
        </Tabs>
    );
};

export default Calendar;