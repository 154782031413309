import React, { useEffect, useRef, useState } from 'react';
import OfferData from '../../models/offerModel';
import './accordingList.css';
import ImageGallery from "react-image-gallery";
// import stylesheet if you're not already using CSS @import
import "react-image-gallery/styles/css/image-gallery.css";
import Calendar from '../calendar/calendar';

interface TableProps {
    accData?: OfferData;
    selectMarker: (marker: number) => void;
    selectedMarkerOnMap?: number;
}

interface ImageType {
    original: string,
    thumbnail: string,
}


const AccordingList: React.FC<TableProps> = ({ accData, selectMarker, selectedMarkerOnMap }) => {
    const [activeId, setActiveId] = useState<number | null>(null);
    const activeBlockRef = useRef<HTMLDivElement>(null);
  
  

    const toggleAccordion = (id: number) => {
        selectMarker(id);
        setActiveId(prevId => (prevId === id ? null : id));
    };


    useEffect(() => {
        if (selectedMarkerOnMap) {
          setActiveId(selectedMarkerOnMap);
        }
    
        if (activeBlockRef.current) {
          activeBlockRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, [selectedMarkerOnMap]);

    return (
        <div className="accordion accordion-flush" id="accordionFlushExample">
            {accData && accData.rows.map((x) => {
                const isOpen = activeId === x.id;
                const region = x.columns.filter(x => x.name === "region")[0]?.value;
                const city = x.columns.filter(x => x.name === "city")[0]?.value;
                const address = x.columns.filter(x => x.name === "address")[0]?.value;
                const side = x.columns.filter(x => x.name === "side")[0]?.value;
                const code = x.columns.filter(x => x.name === "code")[0]?.value;

                const type = x.columns.filter(x => x.name === "type")[0]?.value;
                const light = x.columns.filter(x => x.name === "light")[0]?.value;

   
                const price = x.columns.filter(x => x.name === "price")[0]?.value;
                const requirements = x.columns.filter(x => x.name === "requirements")[0]?.value;
                const install = x.columns.filter(x => x.name === "install")[0]?.value;
                const tax = x.columns.filter(x => x.name === "tax")[0]?.value;
                const owner = x.columns.filter(x => x.name === "owner")[0]?.value;
                const imagesGall: ImageType[] = [];

                if(x.images.length !== 0) {
                    x.images.map(x=> {
                        imagesGall.push({
                            original: "https://keylist.io" + x.url,
                            thumbnail: "https://keylist.io" +  x.url
                        });
                    })

                }


                return (
                    <div
                        className={`accordion-item rounded-3 border-0 shadow mb-2 ${isOpen ? 'open' : ''}`}
                        key={x.id}
                        ref={isOpen ? activeBlockRef : null}
                    >
                        <div className='gid-block'>
                            <span>КОД:  {code}</span>
                        </div>
                        <button
                            className="accordion-button border-bottom collapsed fw-semibold"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#flush-collapse-${x.id}`}
                            aria-controls={`flush-collapse-${x.id}`}
                            onClick={() => toggleAccordion(x.id)}

                        >
                            {region}, {city}, {address}
                        </button>

                        <div
                            id={`flush-collapse-${x.id}`}
                            className={`accordion-collapse collapse ${isOpen ? 'show' : ''}`}
                            data-bs-parent="#accordionFlushExample"
                            style={{ maxHeight: isOpen ? 'none' : '0' }}
                        >
                            <div className="accordion-body">
                                <div className='row'>

                                    <div className="col-6">
                                        <div className='f-text'>Код</div>
                                        <div className='s-text pb-4'>{code}</div>
                                        <div className='f-text'>Область</div>
                                        <div className='s-text pb-4'>{region}</div>
                                        <div className='f-text'>АДРЕС, НАПРАВЛЕНИЕ</div>
                                        <div className='s-text pb-4'>{address}</div>

                                        <div className='f-text pb-2'>ИНФОРМАЦИЯ</div>
                                        <div className='s-text pb-2'> <span className='dop-info'>Вид Формат</span> <span>{type ? type : "Не указано"}</span></div>
                                        <div className='s-text pb-2'> <span className='dop-info'>Сторона</span> <span>{side ? side : "Не указано"}</span></div>
                                        <div className='s-text pb-4'> <span className='dop-info'>Свет</span> <span>{light ? light : "Не указано"}</span></div>

                                        <div className='f-text'>ВЛАДЕЛЕЦ</div>
                                        <div className='s-text pb-4'>{owner}</div>


                                    </div>
                                    <div className="col-6">
                                        <div className='f-text'>ТТ К ПЕЧАТИ</div>
                                        <div className='s-text pb-4'> {requirements ? <a href={`https://keylist.io${requirements}`}>Cкачать</a> : "Нет данных" }</div>
                                        <div className='f-text'>Город</div>
                                        <div className='s-text pb-4'>{city}</div>
                                        <div className='f-text'>ФИНАНСЫ</div>
                                        <div className='s-text pb-2'> <span className='dop-info'>Стоимость</span> <span>{price ? price : "Не указано"}</span></div>
                                        <div className='s-text pb-2'> <span className='dop-info'>Монтаж</span> <span>{install ? install : "Не указано"}</span></div>
                                        <div className='s-text pb-2'> <span className='dop-info'>Налог</span> <span>{tax ? tax : "Не указано"}</span></div>
                                    </div>

                                    <div className="col-12 pt-2">
                                        {imagesGall.length !== 0 &&
                                            <ImageGallery 
                                                autoPlay={false} 
                                                showPlayButton={false} 
                                                items={imagesGall} 
                                            />
                                        }
                                    </div>
                                    <div className="col-12 pt-2">
                               
                                        {x.timeline &&
                                        <>
                                        <div className='f-text pb-2'>РАЗМЕЩЕНИЕ</div>

                                        <p className='p-calendar'> занят: <div className='square-status'></div></p>
                                        <Calendar timeline={x} />
                                        </>
                                            
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default AccordingList;