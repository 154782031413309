const styles = [
  /*{
      'featureType': 'administrative',
      'elementType': 'all',
      'stylers': [
          {
              'visibility': 'off'
          }
      ]
  },*/
  {
    'featureType': 'landscape',
    'elementType': 'all',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'landscape',
    'elementType': 'geometry',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'landscape',
    'elementType': 'geometry.fill',
    'stylers': [
      {
        'visibility': 'on'
      },
      {
        'saturation': '27'
      },
      {
        'lightness': '30'
      },
      {
        'gamma': '1'
      },
      {
        'hue': '#0048ff'
      }
    ]
  },
  {
    'featureType': 'landscape',
    'elementType': 'labels',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'landscape.man_made',
    'elementType': 'geometry.fill',
    'stylers': [
      {
        'saturation': '8'
      }
    ]
  },
  {
    'featureType': 'landscape.natural',
    'elementType': 'all',
    'stylers': [
      {
        'visibility': 'on'
      }
    ]
  },
  {
    'featureType': 'landscape.natural',
    'elementType': 'geometry.fill',
    'stylers': [
      {
        'hue': '#00ff20'
      },
      {
        'saturation': '-51'
      },
      {
        'lightness': '-5'
      },
      {
        'gamma': '1.02'
      }
    ]
  },
  {
    'featureType': 'landscape.natural.landcover',
    'elementType': 'all',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'landscape.natural.terrain',
    'elementType': 'all',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'poi',
    'elementType': 'all',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'poi',
    'elementType': 'geometry.fill',
    'stylers': [
      {
        'hue': '#48ff00'
      },
      {
        'saturation': '-33'
      },
      {
        'lightness': '12'
      },
      {
        'gamma': '1.27'
      }
    ]
  },
  {
    'featureType': 'poi.attraction',
    'elementType': 'all',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'poi.business',
    'elementType': 'all',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'poi.government',
    'elementType': 'all',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'poi.medical',
    'elementType': 'all',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'poi.park',
    'elementType': 'all',
    'stylers': [
      {
        'visibility': 'on'
      },
      {
        'hue': '#00ff50'
      }
    ]
  },
  {
    'featureType': 'poi.park',
    'elementType': 'geometry.fill',
    'stylers': [
      {
        'hue': '#00ff20'
      },
      {
        'gamma': '1.27'
      },
      {
        'lightness': '12'
      },
      {
        'saturation': '-33'
      },
      {
        'visibility': 'on'
      }
    ]
  },
  {
    'featureType': 'poi.park',
    'elementType': 'labels.text.fill',
    'stylers': [
      {
        'color': '#001e5a'
      }
    ]
  },
  {
    'featureType': 'poi.park',
    'elementType': 'labels.text.stroke',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'poi.place_of_worship',
    'elementType': 'all',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'poi.school',
    'elementType': 'all',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'poi.sports_complex',
    'elementType': 'all',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'road',
    'elementType': 'all',
    'stylers': [
      {
        'visibility': 'on'
      }
    ]
  },
  {
    'featureType': 'road',
    'elementType': 'geometry.fill',
    'stylers': [
      {
        'gamma': '9.70'
      },
      {
        'color': '#ffffff'
      },
      {
        'visibility': 'on'
      }
    ]
  },
  {
    'featureType': 'road',
    'elementType': 'geometry.stroke',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'road',
    'elementType': 'labels.text.fill',
    'stylers': [
      {
        'color': '#001e5a'
      }
    ]
  },
  {
    'featureType': 'road',
    'elementType': 'labels.text.stroke',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'road',
    'elementType': 'labels.icon',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'road.highway',
    'elementType': 'labels.icon',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'road.highway.controlled_access',
    'elementType': 'labels.icon',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'road.arterial',
    'elementType': 'labels.icon',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'road.local',
    'elementType': 'labels.icon',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'transit',
    'elementType': 'all',
    'stylers': [
      {
        'visibility': 'on'
      },
      {
        'gamma': '1.26'
      }
    ]
  },
  {
    'featureType': 'transit',
    'elementType': 'geometry.fill',
    'stylers': [
      {
        'saturation': '-100'
      },
      {
        'color': '#ffffff'
      },
      {
        'visibility': 'on'
      }
    ]
  },
  {
    'featureType': 'transit',
    'elementType': 'labels.text.fill',
    'stylers': [
      {
        'gamma': '3.83'
      },
      {
        'color': '#001e5a'
      }
    ]
  },
  {
    'featureType': 'transit',
    'elementType': 'labels.text.stroke',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'transit',
    'elementType': 'labels.icon',
    'stylers': [
      {
        'hue': '#2800ff'
      },
      {
        'visibility': 'on'
      },
      {
        'gamma': '0.59'
      }
    ]
  },
  {
    'featureType': 'water',
    'elementType': 'all',
    'stylers': [
      {
        'visibility': 'on'
      },
      {
        'hue': '#0067ff'
      }
    ]
  }
];

export default styles;
