import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "./pages/home";
import NotFound from "./404";



export default function App() {

  return (

    <BrowserRouter>
      <Routes>
        <Route path="/:offerId" element={<Home />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      </BrowserRouter>
      
  );
}
