import React from 'react';

const NotFound = () => {
  return (
    <div className="d-flex align-items-center justify-content-center vh-100 bg-primary">
      <h1 className="display-1 fw-bold text-white">404 - Страница не найдена</h1>
    </div>
    
  );
};

export default NotFound;