import React, { FC, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import OfferData from "../models/offerModel";
import { useClickAway } from "react-use";
import AccordingList from "../components/accordingList/accordingList";
import Maps from "../components/map/map";
import NotFound from "../404";
import axios from "axios";

const Home: FC = () => {
  const { offerId } = useParams<{ offerId?: string }>();
  const [data, setData] = useState<OfferData | undefined>();
  const [selectedMarker, setSelected] = useState<number>();
  const [selectedMarkerOnMap, setSelectedOnMap] = useState<number>();
  const [mapWidth, setMapWidth] = useState<string>("100%");

  const [error, setError] = useState(false);

  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  useClickAway(ref, () => setOpen(false));

  const toggleSidebar = () => {
    setOpen((prev) => !prev);

    const mapDiv = document.getElementById("map");
    if (mapDiv) {
      mapDiv.style.transition = "width 0.3s"; // Set the transition duration
      mapDiv.style.width = open ? "100%" : "70%";
    }
    setMapWidth("100%");
  };

  const selectMarker = (id: number) => {
    setSelected(id);
  };

  const setMarkerIdOnMap = (id: number) => {
    if (selectedMarkerOnMap) {
      if (!open) {
        toggleSidebar();
      }
      setSelectedOnMap(id);
    } else {
      setSelectedOnMap(id);
      toggleSidebar();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://keylist.io/api/offer/${offerId}`
        );
        if (!response.status) {
          throw new Error(`API Error: ${response.statusText}`);
        }
        setData(response.data);
      } catch (error) {
        setError(true);
      }
    };
    fetchData();

    return () => {
      const mapDiv = document.getElementById("map");
      if (mapDiv) {
        mapDiv.style.transition = "none"; // Remove the transition on unmount
      }
    };
  }, [offerId]);

  return (
    <>
      {error ? (
        <NotFound />
      ) : (
        <div>
          {open && (
            <div
              className="custom-scrollbar"
              style={{
                position: "absolute",
                zIndex: "10000",
                width: "30%",
                height: "100%",
                backgroundColor: "white",
                overflowY: "scroll",
                padding: "8px",
              }}
            >
              <AccordingList
                accData={data}
                selectMarker={selectMarker}
                selectedMarkerOnMap={selectedMarkerOnMap}
              />
            </div>
          )}
          <div
            style={{
              height: "100vh",
              width: "100%",
              position: "absolute",
              right: "0px",
            }}
            id="map"
          >
            <button
              type="button"
              className="btn btn-secondary"
              style={{
                zIndex: "10000",
                position: "absolute",
                top: "15px",
                left: "20px",
              }}
              onClick={toggleSidebar}
            >
              {!open ? "Показать список" : "Скрыть список"}
            </button>
            {data && (
              <Maps
                data={data}
                selectedMarker={selectedMarker}
                setMarkerIdOnMap={setMarkerIdOnMap}
                width={mapWidth}
              />
            )}
          </div>
        </div>
      )}{" "}
    </>
  );
};

export default Home;