import { GoogleMap, useLoadScript, MarkerF } from '@react-google-maps/api';
import OfferData from '../../models/offerModel';
import { useEffect, useState } from 'react';
import mapStyles from "../../helpers/map-styles";


interface Position {
    lat: number;
    lng: number;
}


interface Props {
    data: OfferData;
    selectedMarker?: number;
    setMarkerIdOnMap: (markerId: number) => void;
    width: string;
}



const options = {
    disableDefaultUi: true,
    styles: mapStyles,
    zoomControl: true,
    mapTypeControl: true,
    mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: google.maps.ControlPosition.TOP_CENTER,
    },

};

const icon = `
        <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
          <g transform="rotate(#rotate# 16 16)">
            <path opacity="0.7" fill="#FFFFFF" enable-background="new" d="M16,0C7.2,0,0,7.2,0,16s7.2,16,16,16s16-7.2,16-16S24.8,0,16,0z
     M26,17.5H6v-3h20V17.5z"/>
          <rect x="6" y="14.5" fill="#FD3376" width="20" height="3"/>
          <path fill="#FD3376" d="M18.7,8.9l-2.2,2.2V3.3c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5v7.8l-2.2-2.2
    c-0.1-0.1-0.2-0.1-0.3-0.1s-0.3,0-0.4,0.1c-0.2,0.2-0.2,0.5,0,0.7l3,3L16,13l0.4-0.4l3-3c0.2-0.2,0.2-0.5,0-0.7
    C19.2,8.7,18.9,8.7,18.7,8.9z"/>
          </g>
        </svg>
    `;
const Maps = ({ data, selectedMarker, setMarkerIdOnMap, width }: Props) => {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyDoWD40IKD_V60Jl7mYH5rUf8HGkl_Q33c',
    });


    const [mapRef, setMapRef] = useState<google.maps.Map | undefined>();

    const onLoad = (map: any) => {
        setMapRef(map);
    };

    const [position, setPosition] = useState<Position>({
        lat: parseFloat(data.rows[0].gps.split(",")[0]),
        lng: parseFloat(data.rows[0].gps.split(",")[1])
    });

    const [zoom, setZoom] = useState<number>(8);

    const mapContainersStyle = {
        width,
        height: '100vh',
        display: 'block', marginBottom: '10px'
        
    };

    const getMarkerIcon = (deg: string) => {
        const markerIcon = {
            url: 'data:image/svg+xml;utf-8,' + encodeURIComponent(icon.replace('#rotate#', deg)),
            scaledSize: new window.google.maps.Size(32, 32), // Set the desired size
            anchor: new window.google.maps.Point(16, 16), // Set the anchor point
        };
        return markerIcon;
    };

    useEffect(() => {
        if (selectedMarker !== undefined) {
            let row = data.rows.filter((x) => x.id === selectedMarker)[0];
            setPosition({
                lat: parseFloat(row.gps.split(",")[0]),
                lng: parseFloat(row.gps.split(",")[1])
            });
            setZoom(18);
            mapRef?.setZoom(18);
            mapRef?.panTo({
                lat: parseFloat(row.gps.split(",")[0]),
                lng: parseFloat(row.gps.split(",")[1])
            });
        }
    }, [selectedMarker]);


    if (loadError) return <div>Error loading maps</div>;
    if (!isLoaded) return <div>Loading maps...</div>;


    return (
        <>

            <GoogleMap
                mapContainerStyle={mapContainersStyle}
                zoom={zoom}
                center={position}
                options={options}
                onLoad={onLoad}
            >
                {data && data.rows.map((x, index) => {
                    const image = getMarkerIcon(x.angle.toString());
                    return (
                        <MarkerF
                            key={index}
                            position={{ lat: parseFloat(x.gps.split(",")[0]), lng: parseFloat(x.gps.split(",")[1]) }}
                            icon={image}
                            onClick={() => setMarkerIdOnMap(x.id)}
                        ></MarkerF>
                    );
                })}
            </GoogleMap>
        </>
    );
};

export default Maps;